<template>
  <dashboard-content>
    <div class="patient-select">
      <v-row justify="center">
        <v-col class="col-auto">
          <logo-modal />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="text-center"
        >
          <h1
            v-if="!haveChosenClient"
            class="h1 patient-select-title"
          >
            Please select a client
          </h1>
          <h1
            v-else
            class="h1 patient-select-title"
          >
            Client selected!
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <div class="patient-select-input">
            <form-autocomplete-name
              item-text="name"
              item-value="id"
              placeholder="Type client’s name to search"
              return-object
              autofocus
              :find-function="findClients"
              @input="autocompleteInput"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="$vuetify.breakpoint.smAndDown"
        justify="center"
      >
        <v-col class="col-auto">
          <div class="patient-select-add-patient">
            <router-link
              :to="{ name: 'clientAdd' }"
              class="text-decoration-none"
            >
              <div class="add-patient d-flex align-center">
                <div class="add-patient-img d-flex align-center justify-center">
                  <img
                    src="@/assets/img/plus.svg"
                    alt="Add new client"
                  >
                </div>
                <div class="add-patient-text">
                  Add new client
                </div>
              </div>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
  </dashboard-content>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import FormAutocompleteName from '@/components/form/FormAutocompleteName'
import LogoModal from '@/components/LogoModal'

export default {
  name: 'PatientSelect',
  components: { DashboardContent, FormAutocompleteName, LogoModal },
  data() {
    return {
      ...mapGetters('app', ['isLocked']),
    }
  },
  computed: {
    ...mapState('app', ['referer']),
    ...mapGetters('clients', ['haveChosenClient']),
  },
  methods: {
    ...mapActions('clients', ['findClients', 'setClientNew']),
    async autocompleteInput(data) {
      if (data) {
        const routeData = this.referer || null //{ name: 'patientSelected' }
        await this.setClientNew(data)
        this.isClientSelected = true
        // if we're in locked mode for doxyme shared session
        // we need to tell doxyme the client has been selected
        // so they can start their session
        if (this.isLocked()) {
          if (window.parent) {
            window.parent.postMessage('clientSelected', '*')
            console.log('client selected in locked mode')
          }
        }
        if (routeData)
          this.$router.push(routeData)
      }
    }
  },
  head: {
    title: {
      inner: 'Client selection'
    },
    meta: [
      {
        name: 'description',
        content: 'You have chosen client',
        id: 'desc'
      }
    ]
  },
}
</script>

<style scoped lang="scss">
.patient-select {
  &-title {
    margin: 4px 0 15px;
  }

  &-input {
    width: 100%;
    max-width: 488px;
    margin: 0 auto;

    @media (max-width: 599px) {
      max-width: 100%;
    }
  }

  &-add-patient {
    margin-top: 75px;

    .add-patient {
      font-size: 24px;

      &-img {
        width: 26px;
        height: 26px;
        margin-right: 8px;
        border-radius: 50%;
        border: 2px solid var(--v-primary-base);

        img {
          width: 16px;
          height: 16px;
        }
      }

      &-text {
        line-height: 0.9;
        border-bottom: 1px solid var(--v-primary-base);
      }
    }
  }
}
</style>
